import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner2 = ({ strapiData, ruby, unreal = false, breadCrumb }) => {
  return (
    <section className={styles.banner}>
      <Container>
        <Row
          className={`${
            ruby ? "align-items-center" : "align-items-start"
          }  gap-30`}
        >
          <Col lg={6} md={12}>
            {ruby || unreal === false ? null : (
              <nav className="mb-4">
                <Link to={`/`} className="text-dark">
                  Home
                </Link>
                {" » "}
                {breadCrumb?.map((val, i) =>
                  val?.url !== "#" ? (
                    <React.Fragment key={i}>
                      <Link to={`/${val?.url}/`} className="text-dark">
                        {val?.title}
                      </Link>
                      {" » "}
                    </React.Fragment>
                  ) : (
                    <span className="text-dark">{val?.title}</span>
                  )
                )}
              </nav>
            )}
            <h1
              className={styles.mainHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={`${styles.subHeading} pb-4 pt-2`}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />

            <ul className="pr-2 pr-md-0">
              {strapiData?.cards &&
                strapiData?.cards?.map((el, id) => (
                  <Row className="pb-2" key={id}>
                    <Col xs={1} className="pr-0">
                      <div className={styles.liBullet} />
                    </Col>
                    <Col xs={11} className="px-0">
                      <li>{el.title}</li>
                    </Col>
                  </Row>
                ))}
            </ul>
            <div className="pt-4">
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border  "
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <img
              className={styles.bannerImg}
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={"Ruby on Rails Developers"}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner2
